import request from '@/utils/request';
import { getTpDataSign } from '@/utils/rsa.js';
const BASE_URL = process.env.VUE_APP_BASE_API;
const BASE_Mul_URL = process.env.VUE_APP_GADS3;
// 广告数据概览字典项
export function getLogList(params) {
  return request({
    baseURL: BASE_URL,
    url: `/log/selectLogByPage`,
    method: 'get',
    params,
  });
}
export function getMulLogList(params) {
  // let ret = '';
  // for (let it in params) {
  //   if(params[it]){
  //     ret += encodeURIComponent(it) + '=' + encodeURIComponent(params[it]) + '&';
  //   }
  // }
  return request({
    baseURL: BASE_Mul_URL,
    headers: getTpDataSign(params),
    // url: `/log/selectLogByPage?${ret}`,
    url: `/log/selectLogByPage`,
    method: 'get',
    params,
  });
}
