<template>
  <div class="log">
    <page-table
      :searchs="searchs"
      :tableColumn="tableColumn"
      :API="API"
      ref="pageTable"
      isNeedFormatData
      :pageNumberStart="1"
    ></page-table>
  </div>
</template>
<script>
import pageTable from '@/components/common/PageTable';
import { Searchs, Level, OperateType } from './index';

import { getLogList } from '@/api/multiPlatform/log';
export default {
  components: { pageTable },
  data() {
    return {
      searchs: Searchs,
      tableColumn: [
        {
          label: '平台',
          prop: 'platform',
        },
        {
          label: '层级',
          prop: 'level',
          formatter: (row, column, cellValue, index) => {
            if (cellValue) {
              return Level.find((item) => item.value == cellValue).label;
            }
          },
        },
        {
          label: '账户',
          prop: 'adAccountId',
        },
        {
          label: '系列',
          prop: 'campaignId',
        },
        {
          label: '组id',
          prop: 'adGroupId',
        },
        {
          label: '广告id',
          prop: 'adId',
        },
        {
          label: '修改前的值',
          prop: 'oldValue',
        },
        {
          label: '修改后的值',
          prop: 'newValue',
          formatter: (row, column, cellValue, index) => {
            if (row.ifUpdateType) {
              console.log('column', row.ifUpdateType, index);
              return (
                <el-tooltip
                  effect='dark'
                  content={row.ifUpdateType}
                  placement='bottom'
                >
                  <span>
                    {cellValue}
                    <i class='el-icon-info'></i>
                  </span>
                </el-tooltip>
              );
            }
            return cellValue;
          },
        },
        {
          label: '操作人',
          prop: 'operateUserId',
        },
        {
          label: '操作时间',
          prop: 'operateTime',
        },
        {
          label: '操作类型',
          prop: 'operateType',
          formatter: (row, column, cellValue, index) => {
            if (cellValue) {
              return OperateType.find((item) => item.value == cellValue).label;
            }
          },
        },
        {
          label: '操作字段',
          prop: 'fieldName',
        },
      ],
      API: getLogList,
    };
  },
};
</script>
<style lang="scss" scope></style>
