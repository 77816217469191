export const Level = [
  {
    label: '系列',
    value: 'campaign',
  },
  {
    label: '广告组',
    value: 'adset',
  },
  {
    label: '广告',
    value: 'ad',
  },
];

export const OperateType = [
  {
    label: '状态',
    value: 'status',
  },
  {
    label: '日预算',
    value: 'dailyBudget',
  },
  {
    label: '总预算',
    value: 'lifetimeBudget',
  },
  {
    label: '竞价',
    value: 'bidAmount',
  },
  {
    label: '创意',
    value: 'creative',
  },
  {
    label: '受众',
    value: 'audience',
  },
  {
    label: '编辑',
    value: 'edit',
  },
];
export const Searchs = [
  {
    type: 'select',
    prop: 'platform',
    formOptions: {
      placeholder: '平台',
      clearable: true,
    },
    options: [
      { value: 'facebook', label: 'facebook' },
      { value: 'google', label: 'google' },
      { value: 'tiktok', label: 'tiktok' },
      { value: 'line', label: 'line' },
      { value: 'twitter', label: 'twitter' },
      { value: 'snapchat', label: 'snapchat' },
    ],
  },
  {
    type: 'select',
    prop: 'level',
    formOptions: {
      placeholder: '层级',
      clearable: true,
    },
    options: Level,
  },
  {
    type: 'select',
    prop: 'operateType',
    formOptions: {
      placeholder: '操作类型',
      clearable: true,
    },
    options: OperateType,
  },
  {
    type: 'input',
    prop: 'adAccountId',
    formOptions: {
      placeholder: '账号id',
      clearable: true,
    },
  },
  {
    type: 'input',
    prop: 'campaignId',
    formOptions: {
      placeholder: '系列id',
      clearable: true,
    },
  },
  {
    type: 'input',
    prop: 'adGroupId',
    formOptions: {
      placeholder: '组id',
      clearable: true,
    },
  },
  {
    type: 'input',
    prop: 'adId',
    formOptions: {
      placeholder: '广告id',
      clearable: true,
    },
  },
];

